// Utility function to format a number to two decimal places
export const addDecimals = (num) => {
    if (isNaN(num)) {
        throw new Error("Invalid number provided to addDecimals.");
    }
    return (Math.round(num * 100) / 100).toFixed(2);
};

// Function to update the card state
export const updateCard = (state) => {
    // Validate the state object and its properties
    if (!state || !state.cardItems || !Array.isArray(state.cardItems)) {
        throw new Error("Invalid state object. Ensure 'state' has a 'cardItems' array.");
    }

    // Calculate items price (sum of price * quantity for each item)
    const itemsPrice = state.cardItems.reduce((acc, item) => acc + item.price * item.qty, 0);
    state.itemsPrice = addDecimals(itemsPrice);

    // Combined tax rate (TPS + TVQ)
    const taxRate = 0.05 + 0.09975;
    const taxPrice = taxRate * itemsPrice;
    state.taxPrice = addDecimals(taxPrice);

    // Calculate total price (itemsPrice + taxPrice)
    state.totalPrice = addDecimals(Number(state.itemsPrice) + Number(state.taxPrice));

    // Save the updated state to localStorage
    localStorage.setItem('card', JSON.stringify(state));
};