import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import ReservationSteps from '../../components/ReservationSteps';
import { quantityIncrement, quantityDecrement, removeFromCard, clearCardItems } from "./cardSlice";

function ReservationPanier() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const quantityIncrementHandler = (itemId) => {
        dispatch(quantityIncrement(itemId));
    };

    const quantityDecrementHandler = (itemId) => {
        dispatch(quantityDecrement(itemId));
    };

    const card = useSelector((state) => state.card);
    const { cardItems } = useSelector((state) => state.card);

    const removeFromCardHandler = async(serviceId) => {
        dispatch(removeFromCard(serviceId));
    };
  
    const clearCardItemsHandler = () => {
        dispatch(clearCardItems());
    };

    const confirmServiceHandler = () => {
        navigate('/reservation-date');
    };

    return (
        <section className="container py-5">
            <ReservationSteps step1 step2 step3 />
            <div className="text-center py-3">
                <h1>Panier de réservation</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        Pour effectuer une réservation pour un groupe de quatre personnes ou plus, veuillez SVP nous contacter par téléphone au (418) 255-2318.
                    </p>
                </div>
                {card.cardItems.length === 0 && <h4>Votre panier est vide | Your cart is empty!</h4>}
            </div>
            <hr />
            <div className="row">
                {card.cardItems.length === 0 ? (
                    navigate('/reservation-services')
                ) : (
                    <>
                        <div className="row mt-3">
                            <div className="col-md-8">
                                <ul className="list-group">
                                    { cardItems?.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul className="list-group mb-3">
                                                        <li className="list-group-item d-flex justify-content-between lh-sm bg-light">
                                                            <div>
                                                                <h6>{item.titre} ({item.duration} minutes)</h6>
                                                                <div className='text-muted pb-3'>
                                                                    <small>Prix: ${Number(item.price).toFixed(2)} / unité</small>
                                                                </div>
                                                                <div className="btn btn-outline-dark rounded-pill p-0 bg-light" role="toolbar" aria-label="Toolbar with button groups">
                                                                    <div className="btn-group" role="group" aria-label="Second group">
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-light border rounded-start-pill px-3"
                                                                            onClick={() => quantityDecrementHandler(item.serviceId)}
                                                                            disabled={item.qty <= 1}
                                                                        >
                                                                            <span className="fw-bold">
                                                                                -
                                                                            </span>
                                                                        </button>
                                                                        <button type="button" className="btn btn-light border">{item.qty}</button>
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-light border rounded-end-pill px-3"
                                                                            onClick={() => quantityIncrementHandler(item.serviceId)}
                                                                            disabled={item.qty >= item.capacity}
                                                                        >
                                                                            <span className="fw-bold">
                                                                                +
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="text-muted text-center">
                                                                <h6>${Number(item.price * item.qty).toFixed(2)}</h6>
                                                                <hr className='py-2'/>
                                                                <button className="btn btn-outline-danger btn-sm" type='button' variant='light' onClick={() => removeFromCardHandler(item.serviceId)}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <h4 className="text-center text-uppercase mb-3">Détails du service</h4>
                                        <ul className="list-group mb-3">
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">Prix des services</h6>
                                                    <small className="text-muted">({cardItems.reduce((acc, item) => acc + Number(item.qty), 0)} {cardItems.length > 1 ? 'services' : 'service'})</small>
                                                </div>
                                                <span className="text-muted">${ cardItems.reduce((acc, item) => acc + Number(item.qty) * item.price, 0).toFixed(2) }</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">Taxes</h6>
                                                    <small className="text-body-secondary">(TPS et TVQ)</small>
                                                </div>
                                                <span className="text-body-secondary">${card.taxPrice}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between">
                                                <div>
                                                    <h5 className="my-0 text-uppercase">Total</h5>
                                                </div>
                                                <strong>${card.totalPrice}</strong>
                                            </li>
                                        </ul>
                                        <div className="card-footer">
                                            <div className="row d-grid gap-2">
                                                <button 
                                                    type='button' 
                                                    className='btn btn-info btn-lg mt-3'
                                                    onClick={confirmServiceHandler}
                                                    disabled={cardItems.length === 0}
                                                >
                                                    Valider et continuer
                                                </button>
                                                <button 
                                                    type='button' 
                                                    className='btn btn-outline-danger btn-lg my-3'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#emptyCart"
                                                    // onClick={() => clearCartItemsHandler()}
                                                >
                                                    Annuler la commande
                                                </button>
                                                <div className="modal fade" id="emptyCart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content text-center pt-3">
                                                            <div className="modal-body">
                                                                <h5>Êtes-vous sûr de vouloir <span className="text-uppercase">VIDER</span>  ton panier?</h5>
                                                                <h6>Are you sure you want to <span className="text-uppercase">EMPTY</span> your shopping cart?</h6>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-header">
                                                                <button 
                                                                    className="btn btn-danger" 
                                                                    onClick={() => clearCardItemsHandler()}
                                                                    data-bs-dismiss="modal"
                                                                >
                                                                    Oui
                                                                </button>
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Non</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link
                                                    to='/reservation-services'
                                                    type='button'
                                                    role='button'
                                                    className='btn btn-outline-primary btn-lg'
                                                >
                                                    Continuer les achats
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
    </section>
    )
}

export default ReservationPanier